import React, { FC } from 'react';

export interface ToggleOptionProps {
  icon: string,
  value: string
}

//This component stricly holds some data
export const ToggleOption: FC<ToggleOptionProps> = ({icon, value}) => {
    return null;
};
