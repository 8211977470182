import React, { cloneElement, CSSProperties, FC, forwardRef, HTMLProps, ReactElement, useLayoutEffect, useRef } from 'react';
import { RenderInBody } from '../../util/RenderInBody';

export interface TooltipProps extends HTMLProps<HTMLDivElement> {
  position?: 'left' | 'top-left' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left';
  color?: 'default' | 'primary' | 'accent' | 'alert' | 'negative' | 'positive';
  label: string;
  children?: ReactElement<any>;
}

export const Tooltip: FC<TooltipProps> = ({ position = 'top-left', color = 'default', label, children, ...props }) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLElement>(null);

  const cssClasses = ['o4c', 'tooltip', position];

  if (color !== 'default') cssClasses.push(color);

  const className = cssClasses.join(' ');

  const ChildComponent = forwardRef((props, ref) =>
    cloneElement(children, {
      ...props,
      ref,
    })
  );

  //Positioning
  const positionTooltip = () => {
    const child = childRef.current;
    const tooltip = tooltipRef.current;
    const childRect = child.getBoundingClientRect();
    const tooltipRect = tooltip.getBoundingClientRect();
    const childTop = childRect.top + (document.documentElement.scrollTop || window.scrollY);
    const childLeft = childRect.left + (document.documentElement.scrollLeft || window.scrollX);
    const gap = 16; //Gap between the tooltip and the element in px
    const indicatorOffset = 16; //Distance to shift by so the indicator aligns with the child
    const halfChildWidth = childRect.width / 2;
    const halfChildHeight = childRect.height / 2;
    const halfTooltipWidth = tooltipRect.width / 2;
    const halfTooltipHeight = tooltipRect.height / 2;

    let top = 0;
    let left = 0;

    switch (position) {
      case 'left':
        top = childTop + halfChildHeight - halfTooltipHeight;
        left = childLeft - tooltipRect.width - gap;
        break;
      case 'top-left':
        top = childTop - tooltipRect.height - gap;
        left = childLeft - tooltipRect.width + halfChildWidth + indicatorOffset;
        break;
      case 'top':
        top = childTop - tooltipRect.height - gap;
        left = childLeft + halfChildWidth - halfTooltipWidth;
        break;
      case 'top-right':
        top = childTop - tooltipRect.height - gap;
        left = childLeft - indicatorOffset + halfChildWidth;
        break;
      case 'right':
        top = childTop + halfChildHeight - halfTooltipHeight;
        left = childLeft + childRect.width + gap;
        break;
      case 'bottom-right':
        top = childTop + childRect.height + gap;
        left = childLeft - indicatorOffset + halfChildWidth;
        break;
      case 'bottom':
        top = childTop + childRect.height + gap;
        left = childLeft + halfChildWidth - halfTooltipWidth;
        break;
      case 'bottom-left':
        top = childTop + childRect.height + gap;
        left = childLeft - tooltipRect.width + halfChildWidth + indicatorOffset;
        break;
    }

    tooltip.style.transform = `translate(${left}px, ${top}px)`
  };

  //Show on hover
  useLayoutEffect(() => {
    if (childRef.current === null || tooltipRef.current === null) return;

    tooltipRef.current.style.opacity = '0';

    const showTooltip = () => {
      positionTooltip();
      tooltipRef.current.style.opacity = '1';
    };

    const hideTooltip = () => {
      tooltipRef.current.style.opacity = '0';
    };

    childRef.current.addEventListener('mouseover', showTooltip);
    childRef.current.addEventListener('mouseout', hideTooltip);
    childRef.current.addEventListener('focus', showTooltip);
    childRef.current.addEventListener('blur', hideTooltip);

    return () => {
      if (childRef.current === null || tooltipRef.current === null) return;

      childRef.current.removeEventListener('mouseover', showTooltip);
      childRef.current.removeEventListener('mouseout', hideTooltip);
      childRef.current.removeEventListener('focus', showTooltip);
      childRef.current.removeEventListener('blur', hideTooltip);
    };
  }, [children, childRef.current]);

  return (
    <>
      <RenderInBody>
        <div className={className} ref={tooltipRef} {...props}>
          <span>{label}</span>
          <svg className='indicator' viewBox='0 0 16 8'>
            <path d='M0,0l6.59,7.42c0.78,0.78,2.04,0.78,2.82,0L16,0H0z' />
          </svg>
        </div>
      </RenderInBody>
      {children !== undefined && <ChildComponent ref={childRef} />}
    </>
  );
};
